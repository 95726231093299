import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Layout from "./components/Layout";
import { Loader, LoadingOverlay } from "@mantine/core";

const CustomerRoutes = lazy(() => import("Route/customerRoute"));
const OrderRoutes = lazy(() => import("Route/orderRoute"));
const PriceRoutes = lazy(() => import("Route/priceRoute"));
const ProductRoutes = lazy(() => import("Route/productRoute"));
const SettingRoutes = lazy(() => import("Route/settingRoute"));
const SupplierRoutes = lazy(() => import("Route/supplierRoute"));
const TempRoutes = lazy(() => import("Route/tempRoute"));
const InventoryRoutes = lazy(() => import("Route/inventoryRoute"));
const PurchaseRoutes = lazy(() => import("Route/purchaserderRoute"));
const CategoryRoutes = lazy(() => import("Route/categoryRoute"));
const BrandRoutes = lazy(() => import("Route/brandRoute"));
const PricingGroupPromotion = lazy(() => import("pages/menu/price-management/promotion"));
const CustomLogin = lazy(() => import("./pages/authentication/CustomLogin"));
const DashBoard = lazy(() => import("./pages/dashboard"));
const AccountRoutes = lazy(() => import("Route/account"));


function App() {
  return (
    <Suspense
      fallback={
        <LoadingOverlay
          visible={true}
          loader={<Loader variant="bars" />}
          overlayBlur={1}
        />
      }
    >
      <Router>
        <Routes>
          {/* Auth Route */}
          <Route path="/login" element={<CustomLogin />} />
          {/* Acess needed */}
          <Route element={<Layout />}>
            <Route path="/" element={<DashBoard />} />
            <Route path="/menu/inventory/*" element={<InventoryRoutes />} />
            <Route path="/menu/*" element={<CustomerRoutes />} />
            <Route path="/menu/order/*" element={<OrderRoutes />} />
            <Route path="/menu/pricing-group/*" element={<PriceRoutes />} />
            <Route path="/settings/*" element={<SettingRoutes />} />
            <Route path="/menu/temp/*" element={<TempRoutes />} />
            <Route path="/menu/purchase-order/*" element={<PurchaseRoutes />} />
            <Route path="/menu/supplier/*" element={<SupplierRoutes />} />
            <Route path="/menu/promotion" element={<PricingGroupPromotion />} />
            <Route path="/menu/categories/*" element={<CategoryRoutes />} />
            <Route path="/menu/brand/*" element={<BrandRoutes />} />
            <Route path="/menu/product/*" element={<ProductRoutes />} />
            <Route path="/account/*" element={<AccountRoutes />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
