import { createSlice } from '@reduxjs/toolkit'
import { categoriesCreate, categoriesDelete, categoriesEdit, categoriesList, singleCategory } from 'redux/service/menuService/catagoriesService'

const initialState = {
  categoriesListRes: { data: [], isFetching: false, error: null },
  categoriesCreateRes: { data: [], isFetching: false, error: null },
  categoriesDeleteRes: { data: [], isFetching: false, error: null },
  categorySingleRes: { data: [], isFetching: false, error: null },
  categoriesEditRes: { data: [], isFetching: false, error: null },
}

export const catagoriesSlice = createSlice({
  name: 'catagoriesSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: categoriesCreate, name: "categoriesCreateRes" },
      { api: categoriesDelete, name: "categoriesDeleteRes" },
      { api: categoriesEdit, name: "categoriesEditRes" },
      { api: categoriesList, name: "categoriesListRes" },
      { api: singleCategory, name: "categorySingleRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { catagoriesDispatch } = catagoriesSlice.actions
