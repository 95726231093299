import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const SESSION = {
  CTS_TOKEN: "CTS_TOKEN",
  CTS_ROLE: "CTS_ROLE",
  CTS_LABEL: "label",
  CTS_EMPLOYEEID: "CTS_EMPLOYEEID",
  CTS_REFRESH_TOKEN: "CTS_REFRESH_TOKEN",
};

export const OPTIONS = {
  statusOption: ["Active", "Inactive"],
  paymentOption: [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "email", label: "Email/Bank" },
    // { value: "bank", label: "Bank" },
    { value: "unapplied", label: "Unapplied Amt" },
    { value: "overpayment", label: "Over Payment" },
    { value: "card", label: "Credit/Debit" },
    { value: "ctsairpay", label: "Cts Air Pay" },
    { value: "split", label: "Split Payment" },
  ],
  refundOption: [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "card", label: "Credit/Debit" },
  ],

  storeOption: ["Store", "Store & Web"],
  orderOption: [
    "Pending",
    "Order_Preparation",
    "Cancelled",
    "Generate_packing_slip",
    "Intransit",
    "Delivered",
    "Waiting_To_Dispatch",
  ],
  productStock: ["Product InStock", "Product OutStock"],
  productStatus: ["LIVE", "TEMP", "REMOVED"],
  genderOptions: ["Male", "Female"],
  orderTypeOptions: ["Regular Orders", "Custom Orders", "In House"],
  deliveryType: [
    { label: "Pickup", value: "pickup" },
    { label: "Delivery", value: "delivery" },
  ],
  shippingMethod: ["Store pickup", "CTS Delivery", "Direct Shipping"],
};

export const notify = (props) => {
  const errorObj = props.message;
  switch (props.success) {
    case true:
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: `${props.message}`,
        color: "green",
        className: "my-notification-class",
        loading: false,
      });
      break;
    case "warning":
      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        message: `${props.message}`,
        color: "yellow",
        className: "my-notification-class",
        loading: false,
      });
      break;
    case false:
      if (typeof errorObj === "object") {
        for (const key in errorObj) {
          notifications.show({
            withCloseButton: true,
            autoClose: 3000,
            message: errorObj[key][0],
            color: "red",
            className: "my-notification-class",
          });
        }
      } else {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: errorObj,
          color: "red",
          className: "my-notification-class",
        });
      }
      break;
    default:
      notifications.show({
        id: "sucess",
        withCloseButton: true,
        autoClose: 3000,
        message: "Something Went Wrong",
        color: "red",
        className: "my-notification-class",
        loading: false,
      });
      break;
  }
};

export const AddressFormater = (place) => {
  let country,
    province,
    city,
    line1,
    route,
    postal_code = null;
  if (place?.address_components !== undefined) {
    let addrComp = place.address_components;
    for (let i = 0; i < addrComp.length; ++i) {
      var typ = addrComp[i].types;
      if (compIsType(typ, "administrative_area_level_1"))
        province = addrComp[i].long_name; //store the province
      else if (compIsType(typ, "locality"))
        city = addrComp[i].long_name; //store the city
      else if (compIsType(typ, "route"))
        route = addrComp[i].long_name; //store the street
      else if (compIsType(typ, "street_number"))
        line1 = addrComp[i].long_name; //store the street number
      else if (compIsType(typ, "country"))
        country = addrComp[i].long_name; //store the country
      else if (compIsType(typ, "postal_code"))
        postal_code = addrComp[i].long_name; //store the postal_code
      //we can break early if we find all three data
      if (
        province != null &&
        city != null &&
        country != null &&
        postal_code != null &&
        line1 != null &&
        route != null
      )
        break;
    }
    return {
      address: place.formatted_address,
      // country: country,
      province: province,
      city: city,
      postal_code: postal_code,
      // street_number: line1,
      street: line1 + route,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
  }
};
export const compIsType = (t, s) => {
  for (let z = 0; z < t.length; ++z) if (t[z] == s) return true;
  return false;
};

export const CheckYesNo = (value) => {
  if (value === true) {
    return "yes";
  } else {
    return "no";
  }
};

dayjs.extend(utc);
dayjs.extend(timezone);
const displayDateFormat = "YYYY-MM-DD";
const timeZone = dayjs.tz.guess();
export const convertDate = (date) => {
  date = dayjs(date)
    .tz(timeZone)
    .format(displayDateFormat);
  return date;
};

export const formateDate = (dateString) => {
  const formattedDate = dayjs(dateString).tz(timeZone).toDate();
  return formattedDate;
};