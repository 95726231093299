import { createSlice } from '@reduxjs/toolkit'
import {
  commonEssentialListDelete,
  downloadView,
  essentialAddTax, essentialCategoriesList, essentialCategoryProductList,
  essentialCustomerList, essentialList,
  essentialListAdd,
  essentialMultipleDocumentDelete, essentialMultipleImageDelete, essentialProductList, priceGroupSingleCustomer, sendEmail
} from 'redux/service/essentialService/essentialService'


const initialState = {
  essentialCategoriesListRes: { data: [], isFetching: true, error: null },
  essentialListRes: { data: [], isFetching: true, error: null },
  essentialMultipleImageDeleteRes: { data: [], isFetching: true, error: null },
  essentialMultipleDocumentDeleteRes: { data: [], isFetching: true, error: null },
  essentialAddTaxRes: { data: [], isFetching: false, error: null },
  essentialProductListRes: { data: [], isFetching: false, error: null },
  essentialCustomerListRes: { data: [], isFetching: false, error: null },
  commonEssentialListDeleteRes: { data: [], isFetching: false, error: null },
  essentialCategoryProductListRes: { data: [], isFetching: false, error: null },
  downloadViewRes: { data: [], isFetching: false, error: null },
  sendEmailRes: { data: [], isFetching: false, error: null },
  essentialListAddRes: { data: [], isFetching: false, error: null },
  priceGroupSingleCustomerRes: { data: [], isFetching: false, error: null },
}

export const essentialSlice = createSlice({
  name: 'essentialSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: essentialAddTax, name: "essentialAddTaxRes" },
      { api: essentialCategoriesList, name: "essentialCategoriesListRes" },
      { api: essentialList, name: "essentialListRes" },
      { api: essentialMultipleDocumentDelete, name: "essentialMultipleDocumentDeleteRes" },
      { api: essentialMultipleImageDelete, name: "essentialMultipleImageDeleteRes" },
      { api: essentialProductList, name: "essentialProductListRes" },
      { api: essentialCustomerList, name: "essentialCustomerListRes" },
      { api: commonEssentialListDelete, name: "commonEssentialListDeleteRes" },
      { api: essentialCategoryProductList, name: "essentialCategoryProductListRes" },
      { api: essentialListAdd, name: "essentialListAddRes" },
      { api: sendEmail, name: "sendEmailRes" },
      { api: downloadView, name: "downloadViewRes" },
      { api: priceGroupSingleCustomer, name: "priceGroupSingleCustomerRes" },

    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { essentialDispatch } = essentialSlice.actions
