import { createSlice } from "@reduxjs/toolkit";
import {
  ImageUploadPost,
  ImageDocUploadPost,
  ImageDocUploadView,
  ImageDocUploadDelete,
} from "redux/service/commonService/imageService";
const initialState = {
  CommonSelectDeleteRes: { data: [], isFetching: false, error: null },
  ImageUploadPostRes: { data: [], isFetching: false, error: null },
  ImageDocUploadPostRes: { data: [], isFetching: false, error: null },
  ImageDocUploadViewRes: { data: [], isFetching: true, error: null },
  ImageDocUploadDeleteRes: { data: [], isFetching: true, error: null },
};
// common slice
export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: ImageUploadPost, name: "ImageUploadPostRes" },
      { api: ImageDocUploadPost, name: "ImageDocUploadPostRes" },
      { api: ImageDocUploadView, name: "ImageDocUploadViewRes" },
      { api: ImageDocUploadDelete, name: "ImageDocUploadDeleteRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { commonDispatch } = commonSlice.actions;
