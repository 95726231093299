import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import { Provider } from 'react-redux';
import { MantineProvider } from "@mantine/core";
import configureAppStore from './store/store';
import { Notifications } from '@mantine/notifications';
import './index.css';
// import './styles/fonts/fonts.css'
import './styles/new-font/newFont.css'
import 'react-image-upload/dist/index.css'

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureAppStore()

root.render(
  <Provider store={store}>
    <MantineProvider theme={{
      loader: 'dots',
      fontFamily: 'Manrope,Regular',
      fontFamilyMonospace: 'Manrope,Regular',
      headings: { fontFamily: 'Manrope,SemiBold' },
      colors: {
        'font-color': ['#131133', '#302F46', '#393939', '#5A5A5A', "#0066B1", "#D9D9D9"],
        'success': ['#0AD27B'],
        'danger': ['#E63C3C'],
        'orange': ['#E6A23C'],
        'paper': ["#F9F9F9"],
        'deep-blue': ["#83A1B7", "#6A94B3", "#4E89B5", "#397FB2", "#2776B0", "#136DAF", "#0066B1", "#10598F", "#1A4F76", "#1F4662", "#223E52", "#233745", "#23313B"]
      },
      black: "#131133",
      primaryColor: 'deep-blue',
      components: {
        Button: {
          styles: (theme) => ({
            fontWeight: "normal"
          })
        },
        Paper: {
          styles: (theme) => ({
            root: {
              backgroundColor: "#F9F9F9",
            },
          })
        }
      },
      fontSizes: {
        xs: '0.70rem',
        sm: '0.77rem',
        md: '0.80rem',
        lg: '0.91rem',
        xl: '0.99rem',
      },
    }}>
      <Notifications position="top-right" zIndex={2077} />
      <App />
    </MantineProvider>
  </Provider>
);