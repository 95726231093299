import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";
import { PDFDocument } from "pdf-lib";

// essential categories List
export const essentialCategoriesList = createAsyncThunk(
  "essential-categories-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/categories?children`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// essential All List

export const essentialList = createAsyncThunk(
  "essential-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/commonlist?required=${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// essential common list add
export const essentialListAdd = createAsyncThunk(
  "essential-list-add",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/commonAdd `, bodyParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Multiple Image Delete
export const essentialMultipleImageDelete = createAsyncThunk(
  "essential-image-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/productimage/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Multiple Document Delete
export const essentialMultipleDocumentDelete = createAsyncThunk(
  "essential-document-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/productdocument/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Add Tax
export const essentialAddTax = createAsyncThunk(
  "essential-add-tax",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/tax`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Add Product Search List
export const essentialProductList = createAsyncThunk(
  "essential-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productslists`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Add Product Category Search List
export const essentialCategoryProductList = createAsyncThunk(
  "essential-Category-product-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productslists`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Customer List with out pagination with search
export const essentialCustomerList = createAsyncThunk(
  "essential-customer-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customerslist`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Select Delete
export const commonEssentialListDelete = createAsyncThunk(
  "common-select-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/commondelete/${bodyParams.id}?type=${bodyParams.type}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Send Email
export const sendEmail = createAsyncThunk(
  "send-email",
  async ({ id, name, data }, thunkAPI) => {
    try {
      const response = await API.post(`/send_email/${id}/${name}`, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Purchase Order Download

export const downloadView = createAsyncThunk(
  "download-view",
  async ({ id, name, type }, thunkAPI) => {
    try {
      const response = await API.get(`/pdf_download/${id}/${name}`, {
        responseType: "blob",
      }).then((blob) => {
        if (type === "print") {
          const url = URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const printWindow = window.open(url);
          printWindow.onload = () => {
            printWindow.print();
          };
          // const printWindow = window.open("", "", "width=800,height=600");
          // const iframe = document.createElement("iframe");
          // iframe.style.width = "100%";
          // iframe.style.height = "100%";
          // iframe.src = url;
          // iframe.onload = () => {
          //   iframe.contentWindow.print();
          // };
          // printWindow.document.body.appendChild(iframe);
        } else {
          const imageType = blob.type.split("/");
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = `${name}.pdf`;
          a.click();
        }
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const downloadView = createAsyncThunk(
//   "download-view",
//   async ({ id, name, type }, thunkAPI) => {
//     try {
//       const response = await API.get(`/pdf_download/${id}/${name}`, {
//         responseType: "arraybuffer",
//       });

//       const pdfDoc = await PDFDocument.load(response);

//       if (type === "print") {
//         const printWindow = window.open("", "", "width=800,height=600");
//         const pdfBytes = await pdfDoc.save();
//         const blob = new Blob([pdfBytes], { type: "application/pdf" });
//         const url = URL.createObjectURL(blob);
//         const iframe = document.createElement("iframe");
//         iframe.style.width = "100%";
//         iframe.style.height = "100%";
//         iframe.src = url;
//         iframe.onload = () => {
//           iframe.contentWindow.print();
//         };
//         printWindow.document.body.appendChild(iframe);
//       } else {
//         const pdfBytes = await pdfDoc.save();
//         const blob = new Blob([pdfBytes], { type: "application/pdf" });
//         const url = URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = `${name}.pdf`;
//         a.click();
//         URL.revokeObjectURL(url);
//       }

//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// price Group list taken by customer
export const priceGroupSingleCustomer = createAsyncThunk(
  "price-customer-single",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customerpricinggroups/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
