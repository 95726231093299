import { createSlice } from "@reduxjs/toolkit";
import {
  priceGroupCategoriesList,
  priceGroupCustomersList,
  priceGroupDelete,
  priceGroupGenralInfoCreate,
  priceGroupGenralInfoEdit,
  priceGroupList,
  priceGroupProductsAdd,
  priceGroupProductsList,
  priceGroupSingleView,
  promotionCreate,
  promotionList,
  promotionDelete,
  promotionView,
  promotionProductsList,
  promotionProductsDelete,
  promotionProductsUpdate
} from "redux/service/menuService/priceService";

const initialState = {
  priceGroupListRes: { data: [], isFetching: true, error: null },
  priceGroupGenralInfoCreateRes: { data: [], isFetching: false, error: null },
  priceGroupSingleViewRes: { data: [], isFetching: false, error: null },
  priceGroupGenralInfoEditRes: { data: [], isFetching: true, error: null },
  priceGroupCategoriesListRes: { data: [], isFetching: true, error: null },
  priceGroupProductsAddRes: { data: [], isFetching: true, error: null },
  priceGroupCustomersListRes: { data: [], isFetching: true, error: null },
  priceGroupProductsListRes: { data: [], isFetching: true, error: null },
  priceGroupDeleteRes: { data: [], isFetching: false, error: null },
  promotionCreateeRes: { data: [], isFetching: false, error: null },
  promotionListRes: { data: [], isFetching: true, error: null },
  promotionDeleteRes: { data: [], isFetching: false, error: null },
  promotionViewRes: { data: [], isFetching: false, error: null },
  promotionProductsListRes: { data: [], isFetching: true, error: null },
  promotionProductsDeleteRes: { data: [], isFetching: true, error: null },
  promotionProductsUpdateRes: { data: [], isFetching: true, error: null },
};

export const pricingGroupSlice = createSlice({
  name: "pricingGroupSlice",
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: priceGroupList, name: "priceGroupListRes" },
      {
        api: priceGroupGenralInfoCreate,
        name: "priceGroupGenralInfoCreateRes",
      },
      { api: priceGroupSingleView, name: "priceGroupSingleViewRes" },
      { api: priceGroupGenralInfoEdit, name: "priceGroupGenralInfoEditRes" },
      { api: priceGroupCategoriesList, name: "priceGroupCategoriesListRes" },
      { api: priceGroupProductsAdd, name: "priceGroupProductsAddRes" },
      { api: priceGroupCustomersList, name: "priceGroupCustomersListRes" },
      { api: priceGroupProductsList, name: "priceGroupProductsListRes" },
      { api: priceGroupDelete, name: "priceGroupDeleteRes" },
      { api: promotionCreate, name: "promotionCreateeRes" },
      { api: promotionList, name: "promotionListRes" },
      { api: promotionDelete, name: "promotionDeleteRes" },
      { api: promotionView, name: "promotionViewRes" },
      { api: promotionProductsList, name: "promotionProductsListRes" },
      { api: promotionProductsDelete, name: "promotionProductsDeleteRes" },
      { api: promotionProductsUpdate, name: "promotionProductsUpdateRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { pricingGroupDispatch } = pricingGroupSlice.actions;
