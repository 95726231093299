import {Box, Flex, Group, Image, Menu, Text} from "@mantine/core";
import {notify} from "helpers/Golbal";
import {unsetSessionStorage} from "helpers/SessionConfig";
import {myProfileIcon, notifyIcon, returnIcon} from "helpers/images";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authLogout} from "redux/service/authService/authService";
import Styles from "styles/style-Api/style";

const CustomHeader = () => {
  const {classes} = Styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = {name: "Admin", image: ""};
  const handleLogout = async () => {
    try {
      const response = await dispatch(authLogout()).unwrap();
      notify(response);
      unsetSessionStorage();
      navigate("/login");
    } catch (error) {
      notify(error);
    }
  };
  return (
    <>
      <Group position="right">
        <Flex
          gap={10}
          align={"center"}
          p={5}
          sx={{
            background: "#F9F9F9",
            borderRadius: ".75rem",
          }}
        >
          <Image width={"1.7rem"} src={notifyIcon} />
          <Text
            color="font-color.1"
            weight={500}
            size="sm"
            sx={{lineHeight: 1}}
            mr={3}
          >
            Hi,{user.name}
          </Text>
          <Menu
            position="top-end"
            offset={2}
            arrowPosition="center"
            classNames={classes}
            shadow="md"
          >
            <Menu.Target>
              <Image width={"1.7rem"} src={myProfileIcon} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => handleLogout()}
                icon={<Image width={15} src={returnIcon} />}
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Group>
    </>
  );
};

export default CustomHeader;
