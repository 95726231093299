import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// priceGroup List
export const priceGroupList = createAsyncThunk(
  "priceGroup-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/pricinggroups`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Delete
export const priceGroupDelete = createAsyncThunk(
  "priceGroup-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/pricinggroup/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Categories List
export const priceGroupCategoriesList = createAsyncThunk(
  "priceGroup-categories-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/listcategories/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Categories add
export const priceGroupCategoriesAdd = createAsyncThunk(
  "priceGroup-categories-add",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/addcategories`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Categories update
export const priceGroupCategoriesUpdate = createAsyncThunk(
  "priceGroup-categories-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/updatecategory`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Products List
export const priceGroupProductsList = createAsyncThunk(
  "priceGroup-products-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/listproducts/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Products add
export const priceGroupProductsAdd = createAsyncThunk(
  "priceGroup-products-add",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/addproducts`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Products update
export const priceGroupProductsUpdate = createAsyncThunk(
  "priceGroup-products-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/updateproduct`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Customer List
export const priceGroupCustomersList = createAsyncThunk(
  "priceGroup-customers-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/listcustomers/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Customer add
export const priceGroupCustomerAdd = createAsyncThunk(
  "priceGroup-customers-add",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/addcustomers`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Create
export const priceGroupGenralInfoCreate = createAsyncThunk(
  "priceGroup-general-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/pricinggroup`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Edit
export const priceGroupGenralInfoEdit = createAsyncThunk(
  "priceGroup-general-edit",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `/pricinggroup/${bodyParams.id}`,
        bodyParams.formData
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// priceGroup Delete
export const priceGroupCustomerDelete = createAsyncThunk(
  "priceGroup-general-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/removecustomer/${bodyParams.customer_id}/${bodyParams.id}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Single View
export const priceGroupSingleView = createAsyncThunk(
  "priceGroup-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/pricinggroup/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion create
export const promotionCreate = createAsyncThunk(
  "priceGroup-promotion-general-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/promotion`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion list
export const promotionList = createAsyncThunk(
  "promoton-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/promotions?page=${bodyParams.page}&&per_page=${bodyParams.per_page}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion view
export const promotionView = createAsyncThunk(
  "promotion-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/promotion/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion delete
export const promotionDelete = createAsyncThunk(
  "promotion-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/promotion/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion Edit
export const promotionEdit = createAsyncThunk(
  "promotion-edit",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/promotion/${bodyParams.id}`,
        bodyParams.formData
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Categories delete View
export const priceGroupCategoriesDelete = createAsyncThunk(
  "priceGroup-categorire-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/removecategory/${bodyParams.customer_id}/${bodyParams.id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// priceGroup Product delete View
export const priceGroupProductDelete = createAsyncThunk(
  "priceGroup-product-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/removeproduct/${bodyParams.customer_id}/${bodyParams.id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion product list
export const promotionProductsList = createAsyncThunk(
  "promotion-products-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/promotion/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// promotion Products add
export const promotionProductsAdd = createAsyncThunk(
  "promotion-products-add",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/promotion/addproduct`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// promotion Products delete
export const promotionProductsDelete = createAsyncThunk(
  "promotion-products-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/promotionproductdelete/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//promotion products update
export const promotionProductsUpdate = createAsyncThunk(
  "promotion-products-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/promotionproduct/${bodyParams.id}`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
