import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//unpaid-credits
export const unpaidCreditList = createAsyncThunk(
  "unpaid-credits",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/customer/${bodyParams}/unpaid-credits`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//payment pending
export const paymentpendingView = createAsyncThunk(
  "payment-pending",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(
        `/viewoverpaymentmemoandpending/${bodyParams}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//receivable-create
export const receivableCreate = createAsyncThunk(
  "receivable-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`mapamountwithinvoices`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//ctsairpay-create
export const ctsAirPayCreate = createAsyncThunk(
  "ctsairpay-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`payment`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// receivepayment-create
export const receivePayment = createAsyncThunk(
  "receivepayment-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`receivepayment`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// receivepayment-get
export const receivePaymentList = createAsyncThunk(
  "receivepayment-get",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/receivepayments?customer=${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// ctsairpay-confirm
export const ctsairpayConfirm = createAsyncThunk(
  "ctsairpay-confirm",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(
        `retrivecheckoutReceivePayment`,
        bodyParams,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// overpayment-get
export const overPaymentList = createAsyncThunk(
  "overpayment-get",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/viewoverpayment/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//splitpayment-create
export const splitPaymentCreate = createAsyncThunk(
  "splitpayment-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`splitpayment`, bodyParams, {});
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//refund-create
export const refundCreate = createAsyncThunk(
  "refund-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`refundReceivePayment`, bodyParams, {});
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// reference-get
export const referenceGet = createAsyncThunk(
  "referenceno-get",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/referenceno`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
