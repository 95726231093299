import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "utils/api";

// Return order List
export const returnOrderList = createAsyncThunk(
  'return-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/returnorders`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Order return
export const orderReturn = createAsyncThunk(
  "order-return",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/returnProduct`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order return single view
export const orderReturnSingleView = createAsyncThunk(
  "order-return-single",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/returnorder/${bodyParams}`,);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);