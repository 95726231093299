import { createSlice } from "@reduxjs/toolkit";
import {
  createOrder,
  orderAdditionalInfo,
  orderFinalTotal,
  orderList, createOrderPreparation, orderProductSpecialOffer, orderSingle, tempOrderList,
  tempOrderSingleDelete, tempOrderView, temporderCreate, temporderDelete, temporderUpdate,
  viewAllCustomerCredit, createOrderProcess,
  createOrderDispatch, createOrderCompleted, orderdispatchList, createOrderInTransit,
  tempAdditionalSingleView, backOrderProcessing, backOrderPreparation,
  backOrderDispatch, listBackOrderApi,
  orderCompleteSingleView,
  singleBackOrderApi,
  temporderCustomerChange,
  cancelOrderApi,
  orderShipingAdressChange,
  checkCustomerOpenOrder,
  compeleteOrderList,
  deliveryStatusList,
  lowStockOrderSingleView,
  lowStockOrderList
} from "redux/service/menuService/orderService";

const initialState = {
  orderListRes: { data: [], isFetching: true, error: null },
  tempOrderViewRes: { data: [], isFetching: true, error: null },
  viewAllCustomerCreditRes: { data: [], isFetching: true, error: null },
  temporderCreateRes: { data: [], isFetching: true, error: null },
  temporderDeleteRes: { data: [], isFetching: true, error: null },
  temporderUpdateRes: { data: [], isFetching: true, error: null },
  orderProductSpecialOfferRes: { data: [], isFetching: false, error: null },
  orderAdditionalInfoRes: { data: [], isFetching: false, error: null },
  createOrderRes: { data: [], isFetching: false, error: null },
  tempOrderListRes: { data: [], isFetching: false, error: null },
  tempOrderSingleDeleteRes: { data: [], isFetching: false, error: null },
  orderSingleRes: { data: [], isFetching: true, error: null },
  orderFinalTotalRes: { data: [], isFetching: true, error: null },
  orderPreparationRes: { data: [], isFetching: true, error: null },
  createOrderProcessRes: { data: [], isFetching: true, error: null },
  createOrderDispatchRes: { data: [], isFetching: true, error: null },
  createOrderCompletedRes: { data: [], isFetching: true, error: null },
  orderdispatchListRes: { data: [], isFetching: true, error: null },
  createOrderInTransitRes: { data: [], isFetching: true, error: null },
  tempAdditionalSingleViewRes: { data: [], isFetching: true, error: null },
  backOrderDispatchRes: { data: [], isFetching: true, error: null },
  backOrderPreparationRes: { data: [], isFetching: true, error: null },
  backOrderProcessingRes: { data: [], isFetching: true, error: null },
  listBackOrderApiRes: { data: [], isFetching: true, error: null },
  orderCompleteSingleViewRes: { data: [], isFetching: true, error: null },
  singleBackOrderApiRes: { data: [], isFetching: true, error: null },
  temporderCustomerChangeRes: { data: [], isFetching: true, error: null },
  cancelOrderApiRes: { data: [], isFetching: true, error: null },
  orderReturnRes: { data: [], isFetching: true, error: null },
  orderShipingAdressChangeRes: { data: [], isFetching: true, error: null },
  checkCustomerOpenOrderRes: { data: [], isFetching: true, error: null },
  lowStockOrderListRes: { data: [], isFetching: true, error: null },
  lowStockOrderSingleViewRes: { data: [], isFetching: true, error: null },
};

export const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: orderList, name: "orderListRes" },
      { api: tempOrderView, name: "tempOrderViewRes" },
      { api: viewAllCustomerCredit, name: "viewAllCustomerCreditRes" },
      { api: temporderCreate, name: "temporderCreateRes" },
      { api: temporderDelete, name: "temporderDeleteRes" },
      { api: temporderUpdate, name: "temporderUpdateRes" },
      { api: orderProductSpecialOffer, name: "orderProductSpecialOfferRes" },
      { api: orderAdditionalInfo, name: "orderAdditionalInfoRes" },
      { api: createOrder, name: "createOrderRes" },
      { api: tempOrderList, name: "tempOrderListRes" },
      { api: tempOrderSingleDelete, name: "tempOrderSingleDeleteRes" },
      { api: orderSingle, name: "orderSingleRes" },
      { api: orderFinalTotal, name: "orderFinalTotalRes" },
      { api: createOrderPreparation, name: "orderPreparationRes" },
      { api: createOrderProcess, name: "createOrderProcessRes" },
      { api: createOrderDispatch, name: "createOrderDispatchRes" },
      { api: createOrderCompleted, name: "createOrderCompletedRes" },
      { api: orderdispatchList, name: "orderdispatchListRes" },
      { api: createOrderInTransit, name: "createOrderInTransitRes" },
      { api: tempAdditionalSingleView, name: "tempAdditionalSingleViewRes" },
      { api: backOrderProcessing, name: "backOrderProcessingRes" },
      { api: backOrderPreparation, name: "backOrderPreparationRes" },
      { api: backOrderDispatch, name: "backOrderDispatchRes" },
      { api: listBackOrderApi, name: "listBackOrderApiRes" },
      { api: orderCompleteSingleView, name: "orderCompleteSingleViewRes" },
      { api: singleBackOrderApi, name: "singleBackOrderApiRes" },
      { api: temporderCustomerChange, name: "temporderCustomerChangeRes" },
      { api: cancelOrderApi, name: "cancelOrderApiRes" },
      { api: orderShipingAdressChange, name: "orderShipingAdressChangeRes" },
      { api: checkCustomerOpenOrder, name: "checkCustomerOpenOrderRes" },
      { api: compeleteOrderList, name: "orderListRes" },
      { api: deliveryStatusList, name: "orderListRes" },
      { api: lowStockOrderList, name: "lowStockOrderListRes" },
      { api: lowStockOrderSingleView, name: "lowStockOrderSingleViewRes" },

    ];

    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
});

export const { orderDispatch } = orderSlice.actions;