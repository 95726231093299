import {AppShell} from "@mantine/core";
import React, {useState} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import CustomNavbar from "./NavBar/navbar";
import CustomHeader from "./Header/header";
import {getSessionStorage} from "../../helpers/SessionConfig";
import {SESSION} from "../../helpers/Golbal";

const Layout = () => {
  const token = () => getSessionStorage(SESSION.CTS_TOKEN);
  const [closeNavbar, setCloseNavbar] = useState(true);
  const [navbarStatus, setNavbarStatus] = useState(false);
  const handleClose = (value) => setNavbarStatus(value);
  const location = useLocation();
  return token() ? (
    <AppShell
      padding="md"
      navbar={
        <CustomNavbar
          closeNavbar={closeNavbar}
          handleClose={(value) => handleClose(value)}
        />
      }
      styles={(theme) => ({
        main: {backgroundColor: "#FFF"},
      })}
      sx={{
        overflow: "hidden",
      }}
    >
      <CustomHeader />
      <div
        onClick={() => {
          navbarStatus && setCloseNavbar(!closeNavbar);
        }}
        className="layout-inner-design"
      >
        <Outlet />
      </div>
    </AppShell>
  ) : (
    <Navigate to={`/login?path=${location.pathname}`} />
  );
};

export default Layout;
